#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  margin: 0;
  padding: 0;
}

.footer {
  position: absolute;
  bottom: 20px;
  z-index: 100;
  width: 100%;
  padding: 20px;
}
.button ~ .button {
  margin-left: 20px;
}
.button {
  background-repeat: no-repeat;
  background-size: contain;
}
.hidden {
  display: none !important;
}
.next-button {
  display: inline-block;
  background-image: url("/img/buttons/next.png");
  cursor: pointer;
  height: 40px;
  width: 96px;
}

.prev-button {
  display: inline-block;
  background-image: url("/img/buttons/back.png");
  cursor: pointer;
  height: 40px;
  width: 96px;
}

.show-canvas-button {
  display: inline-block;
  background-image: url("/img/buttons/show.png");
  cursor: pointer;
  height: 40px;
  width: 190px;
}

.hide-canvas-button {
  display: inline-block;
  background-image: url("/img/buttons/hide.png");
  cursor: pointer;
  height: 40px;
  width: 190px;
}

.play-button {
  background: url("/img/buttons/play.png");
  display: inline-block;
  cursor: pointer;
  height: 40px;
  width: 111px;
}
.pause-button {
  background: url("/img/buttons/stop.png");
  display: inline-block;
  cursor: pointer;
  height: 40px;
  width: 111px;
}
.ui-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
}

:root {
  --s-width: 900px;
  --s-gutter: 2.5rem;
  --c-background: rgb(0, 0, 0);
  --c-accent: white;
}
.stepper-container {
  width: 100%;
  background: url("/img/gradient.png");
  background-size: contain;
  padding: 40px 0;
  z-index: 99;
}

.stepper {
  margin: auto;
  width: 70%;
  --s-stepper-bullet: 1.5rem;
  --s-stepper-bullet-half: calc(var(--s-stepper-bullet) / 2);
  --step-transition: background 0.5s, color 0.5s;
  --step-content: "✓";
  --step-color: white;
  --step-bar-bg: var(--c-accent);
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: rgb(38, 119, 217);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  position: relative;
  z-index: 99;
  background-color: radial-gradient(
    rgba(100, 100, 100, 0.1),
    rgba(100, 100, 100, 0.2),
    rgba(100, 100, 100, 0.3)
  );
}
.stepper__input {
  counter-increment: steps;
  display: none;
}
.stepper__step {
  counter-increment: current-step;
}
.stepper__input:checked ~ .stepper__step {
  --step-color: rgb(38, 119, 217);
  --step-bar-bg: white;
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: rgb(38, 119, 217);
  --step-content: "";
}
.stepper__input:checked ~ .stepper__step .stepper__content {
  opacity: 1;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.stepper__input:checked + .stepper__step {
  --step-bullet-bg: rgb(38, 119, 217);
  --step-bullet-color: white;
  --step-color: white;
}
.stepper__input:checked + .stepper__step .stepper__button::before {
  box-shadow: 0 0 0 2px #fefefe;
}
.stepper__input:checked + .stepper__step .stepper__content {
  opacity: 1;
  pointer-events: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.stepper__content {
  color: white;
  text-align: center;
  font-style: italic;
  font-weight: 300;
  color: var(--step-color);
  transition: opacity 0.5s 0.05s;
  padding: 2rem;
}
.stepper__content::-moz-selection {
  color: black;
  background: var(--step-bullet-color);
}
.stepper__content::selection {
  color: black;
  background: var(--step-bullet-color);
}
.stepper__button {
  cursor: pointer;
  z-index: 99;
  position: relative;
  text-align: center;
  color: white;
  display: block;
  font-size: 0.8em;
}
.stepper__button::before {
  content: var(--step-content);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto var(--s-stepper-bullet-half);
  height: var(--s-stepper-bullet);
  width: var(--s-stepper-bullet);
  border-radius: var(--s-stepper-bullet);
  transition: var(--step-transition);
  background: var(--step-bullet-bg);
  color: var(--step-bullet-color);
  font-size: 1em;
}
.stepper__button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(var(--s-stepper-bullet-half) / 5);
  background: var(--step-bar-bg);
  transition: var(--step-transition);
  top: var(--s-stepper-bullet-half);
  left: 50%;
  transform: translate(0, -50%);
  z-index: -1;
}
.stepper__step:last-child .stepper__button::after {
  display: none;
}
.stepper--flexbox {
  display: flex;
}
.stepper--flexbox .stepper__step {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.minimap {
  position: absolute;
  left: 40px;
  bottom: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 110;
  width: 900px;
  height: auto;
  transform: scale(0.4);
  transform-origin: 0% 100%;
}
.minimap-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.minimap-max.minimap {
  transform: scale(1) translateX(-50%) translateY(50%);
  left: 50%;
  bottom: 50%;
}

.minimap .circle {
  border: 6px solid #6d6d6d;
  background-color: white;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  transform: translateX(-50%) translateY(50%);
  cursor: pointer;
}
.minimap .radar {
  width: 200px;
  height: 200px;
  transform: translateX(-50%) translateY(50%);
}

.with-transition {
  transition: all ease 0.5s;
}

.circle.checked {
  border: 7px solid white;
  background-color: rgb(38, 119, 217);
}

.canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
}
.canvas.drawing {
  z-index: 99;
  pointer-events: all;
}

.splash-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 199;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading {
  position: absolute;
  top: 40px;
  right: 80px;
  z-index: 99;
}
